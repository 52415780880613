<template>
  <div>
    <Container class="bg-blackish pb-20">
      <Navbar isAlternate />
      <div class="w-7/12 mt-24">
        <h1 class="text-white">
          Transform the workforce you have into the workforce you need.
        </h1>
        <p class="text-xl text-grey-450 mt-4">
          Ydev Academy is to equip our students with the necessary digital and
          future skills to nurture confident, competent and imaginative
          professionals brave enough to pursue their ambitions.
        </p>
        <div class="w-3/6 flex mt-4 space-x-6 mt-6">
          <div class="btn btn--primary w-full">Schedule a call</div>
          <div class="btn btn-silver--outline w-full">Learn More</div>
        </div>
      </div>
      <div class="w-5/12 mt-8">
        <h5 class="text-white">
          We have partnered with organisations across various industries
        </h5>
      </div>
      <div class="flex flex-row space-x-10 mt-8">
        <Microsoft />
        <IBM />
      </div>
    </Container>
    <Benefits isAlternate />
    <Container class="bg-white pt-48 pb-52">
      <div class="w-8/12 mx-auto">
        <h1 class="text-blackish text-center">Why Organizations Choose Us</h1>
        <p class="text-grey-350 text-xl text-center mt-6">
          Our schools are designed to help you acquire skills with real-world
          applications. With project based active learning, your mastery of the
          topics translates into skills that put you in the top percentile in
          the labour market.
        </p>
      </div>

      <div class="mt-16">
        <p class="text-blackish text-5xl leading-14">
          With the pressure on and millions watching the Cleveland Cavaliers
          during the NBA Playoffs, Toptal delivered the talent and expertise
          needed to launch a brand new fan engagement platform.
        </p>
      </div>

      <div class="grid grid-cols-3 gap-x-10 mt-20">
        <TestimonialAuthor
          v-for="(testimonial, index) in testimonials"
          :key="index"
          :description="testimonial.authorWork"
          :imageUrl="testimonial.imageUrl"
          :isActive="testimonial.isActive"
          :author="testimonial.author"
          @click="toggleActiveTestimonial(index)"
          isAlternate
        />
      </div>
    </Container>
    <Performances
      isAlternate
      :title="'The journey so far and our collective achievements '"
      :description="'We are uniquely positioned to capitalize on this tremendous opportunity to deliver cutting-edge and culturally competent software to financial institutions.'"
    />

    <Container class="bg-blackish pt-48 pb-40">
      <div class="w-10/12 mx-auto">
        <h1 class="text-white text-center">Better Learning. Better Results.</h1>
        <h1 class="text-white text-center">
          Ydev end to end application process
        </h1>
      </div>
      <ProcessListAlternate />
    </Container>
    <FAQ isAlternate />
    <Container class="bg-white py-44">
      <div class="w-7/12">
        <h1>Digital skills that translate to the workplace.</h1>
        <p class="text-xl text-grey-350 mt-4">
          We do two things extremely well. First, we are committed to getting
          you hired by training you in the digital skills and confidence you
          need. Second, we can accelerate your career by teaching and exposing
          you to new tools and methods so that you can produce results and bring
          greater value to your workplace.
        </p>
      </div>
      <div class="grid grid-cols-3 gap-14 mt-14">
          <div v-for="(skill, index) in skills" :key="skill.title + index">
              <div class="w-32 h-32 rounded-full bg-primary"></div>
              <h5 class="mt-4"> {{ skill.title}}</h5>
              <p class="text-grey-350 text-xl mt-4">{{ skill.description }}</p>
          </div>
      </div>
    </Container>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/blocks/Navbar";
import Container from "@/components/blocks/Container";
import Microsoft from "../../public/svg/microsoft-white.svg";
import IBM from "../../public/svg/ibm-white.svg";
import Benefits from "@/components/blocks/Benefits";
import Performances from "@/components/blocks/Performances";
import ProcessListAlternate from "@/components/blocks/ProcessListAlternate";
import FAQ from "@/components/blocks/FAQ";
import TestimonialAuthor from "@/components/blocks/TestimonialAuthor";
import Footer from '@/components/blocks/Footer';

export default {
  name: "Enterprise",
  components: {
    Navbar,
    Container,
    IBM,
    Microsoft,
    Benefits,
    Performances,
    ProcessListAlternate,
    FAQ,
    TestimonialAuthor,
    Footer
  },
  data() {
    return {
      testimonials: [
        {
          authorWork: "Cleveland Cavaliers",
          author: "Conor Kenney, Director of Professional Services",
          imageUrl: "avatar.png",
          isActive: true,
        },
        {
          authorWork: "Cleveland Cavaliers",
          author: "Conor Kenney, Director of Professional Services",
          imageUrl: "avatar.png",
          isActive: false,
        },
        {
          authorWork: "Cleveland Cavaliers",
          author: "Conor Kenney, Director of Professional Services",
          imageUrl: "avatar.png",
          isActive: false,
        },
      ],
      skills: [
          {
              title: 'UI/UX Design',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
          {
              title: 'Data Science',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
          {
              title: 'Product Management',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
          {
              title: 'DevOps',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
          {
              title: 'Software Engineering',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
          {
              title: 'Software Engineering',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
          {
              title: 'DevOps',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
          {
              title: 'Software Engineering',
              description: 'Complete the pre-course work if you are new to coding or want to prepare for the coding challenges.',
              logo: ''
          },
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
</style>