<template>
  <div class="grid grid-cols-3 gap-x-14 mt-20 relative">
    <div class="">
      <div class="flex items-center justify-between">
        <div
          class="w-32 h-32 rounded-full bg-primary flex items-center justify-center relative z-20"
        >
          <ProcessOne />
        </div>
        <GreenChevronOne class="relative right-20 z-20" />
      </div>
      <h5 class="text-primary mt-14">
        Talk to One of Our Industry Experts
      </h5>
      <p class="text-smoke text-xl leading-8 mt-4">
        Complete the pre-course work if you are new to coding or want to prepare
        for the coding challenges.
      </p>
    </div>
    <div>
      <div class="flex items-center justify-between">
        <div
          class="w-32 h-32 rounded-full bg-primary flex items-center justify-center relative z-20"
        >
          <ProcessTwo />
        </div>
        <GreenChevronTwo class="relative right-20 z-20" />
      </div>
      <h5 class="text-primary mt-14">Work with Hand-Selected Talent</h5>
      <p class="text-smoke text-xl leading-8 mt-4">
        Show us that you can think as a software developer by solving a few
        simple algorithmic coding challenges.
      </p>
    </div>

    <div>
      <div class="flex items-center justify-between">
        <div
          class="w-32 h-32 rounded-full bg-primary flex items-center justify-center relative z-20"
        >
          <ProcessThree />
        </div>
      </div>
      <h5 class="text-primary mt-14">The Right Fit, Guaranteed</h5>
      <p class="text-smoke text-xl leading-8 mt-4">
        Get paired to 3 other applicants and build a collaborative project with
        each of them.
      </p>
    </div>
    <div
      class="absolute top-14 h-0 w-9/12 border-dashed border-emerald-550 border-2 z-10"
      style="margin-top: 5px"
    ></div>
  </div>
</template>

<script>
import ProcessOne from "../../../public/svg/quiz-icon.svg";
import ProcessTwo from "../../../public/svg/console-icon.svg";
import ProcessThree from "../../../public/svg/trail-icon.svg";
import GreenChevronOne from "../../../public/svg/green-chevron-1.svg";
import GreenChevronTwo from "../../../public/svg/green-chevron-2.svg";

export default {
  name: "ProcessListAlternate",
  components: {
    ProcessOne,
    ProcessTwo,
    ProcessThree,
    GreenChevronOne,
    GreenChevronTwo,
  },
};
</script>

<style lang="scss" scoped>
</style>